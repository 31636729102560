import { WidgetConfig } from '../types/shared-types';
import {
  TitleAndTaglineViewModel,
  titleAndTaglineViewModelFactory,
} from './title-and-tagline-view-model/titleAndTaglineViewModel';
import {
  ContactSectionViewModel,
  contactSectionViewModelFactory,
} from './contact-section-view-model/contactSectionViewModel';
import {
  DetailsSectionViewModel,
  detailsSectionViewModelFactory,
} from './details-section-view-model/detailsSectionViewModel';
import {
  HeaderViewModel,
  headerViewModelFactory,
} from './header-view-model/headerViewModel';
import {
  DescriptionSectionViewModel,
  descriptionSectionViewModelFactory,
} from './description-section-view-modal/descriptionSectionViewModel';
import { HeaderViewModelBuilder } from './builders/HeaderViewModel.builder';
import { TitleAndTaglineViewModelBuilder } from './builders/TitleAndTaglineViewModel.builder';
import { DetailsSectionViewModelBuilder } from './builders/DetailsSectionViewModel.builder';
import { ContactSectionViewModelBuilder } from './builders/ContactSectionViewModel.builder';
import { DescriptionSectionViewModelBuilder } from './builders/DescriptionSectionViewModel.builder';
import { ConferencePlatform } from '@wix/bookings-uou-types/dist/src';
import {
  PolicySectionViewModel,
  policySectionViewModelFactory,
} from './policy-section-view-model/policySectionViewModel';
import { PolicySectionViewModelBuilder } from './builders/PolicySectionViewModel.builder';

export interface ServicePageViewModel {
  header: HeaderViewModel;
  titleAndTagline: TitleAndTaglineViewModel;
  detailsSection: DetailsSectionViewModel;
  contactSection: ContactSectionViewModel;
  descriptionSection: DescriptionSectionViewModel;
  policySection: PolicySectionViewModel;
}

export const servicePageViewModelFactory = ({
  config,
  translations,
}: {
  config: WidgetConfig;
  translations;
}): ServicePageViewModel => {
  const formatter = (key) => translations[key] ?? key;

  const serviceDto = config?.serviceDto;
  const businessInfo = config?.businessInfo;
  const regionalSettingsLocale =
    businessInfo?.regionalSettingsLocale ?? 'en-US';

  return {
    header: headerViewModelFactory(serviceDto),
    titleAndTagline: titleAndTaglineViewModelFactory(serviceDto),
    detailsSection: detailsSectionViewModelFactory({
      serviceDto,
      regionalSettingsLocale,
      formatter,
    }),
    contactSection: contactSectionViewModelFactory(businessInfo),
    descriptionSection: descriptionSectionViewModelFactory(serviceDto),
    policySection: policySectionViewModelFactory(businessInfo),
  };
};

export const dummyViewModelFactory = ({
  translations,
}): ServicePageViewModel => {
  const formatter = (key) => translations[key] ?? key;
  const title = formatter('dummy-data.title');
  const tagline = formatter('dummy-data.tagline');
  const days = formatter('dummy-data.days');
  const duration = formatter('dummy-data.duration');
  const price = formatter('dummy-data.price');
  const location = formatter('dummy-data.location');
  const description = formatter('dummy-data.description');
  const policy = formatter('dummy-data.policy');
  const phone = formatter('dummy-data.phone');
  const email = formatter('dummy-data.email');
  const address = formatter('dummy-data.address');

  return {
    header: new HeaderViewModelBuilder()
      .withImageUri('11062b_9bfddeca3e7e4ba3a0886902c2ac1fa9~mv2.jpg')
      .withTitle(title)
      .build(),
    titleAndTagline: new TitleAndTaglineViewModelBuilder()
      .withTitle(title)
      .withTagline(tagline)
      .withOnlineProvider(ConferencePlatform.ZOOM)
      .build(),
    detailsSection: new DetailsSectionViewModelBuilder()
      .withDaysOffered(days)
      .withDuration(duration)
      .withPrice(price)
      .withLocation(location)
      .build(),
    contactSection: new ContactSectionViewModelBuilder()
      .withPhone(phone)
      .withEmail(email)
      .withAddress(address)
      .build(),
    descriptionSection: new DescriptionSectionViewModelBuilder()
      .withDescription(description)
      .build(),
    policySection: new PolicySectionViewModelBuilder()
      .withCancellationPolicy(policy)
      .build(),
  };
};
